import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Layout, BlogSearchIndex, SearchResults, Section, CategoryNav, SearchBar } from 'components';
import * as styles from './search.module.scss';

const Search = ({ blogPosts, blogIndex, blogIndexLoading, location }) => {
  const [searchResults, setSearchResults] = useState([]);

  // format queryString
  const parsed = queryString.parse(location.search);
  const { q: query } = parsed;
  const formattedQ = query?.endsWith('/') ? query?.slice(0, -1) : query;

  // START OF LUNR INDEXING
  useEffect(() => {
    if (formattedQ && blogIndex) {
      const search = blogIndex.search(`${formattedQ}*`).map((post) => post);

      const matchingPosts = search.map((searchResult) => blogPosts.nodes.find((post) => post.uid === searchResult.ref));
      setSearchResults(matchingPosts);
    }
  }, [formattedQ, blogIndex]);

  // search logic
  const [displaySearch, setDisplaySearch] = useState(false);

  const handleShowSearch = (event) => {
    if (event) event.preventDefault();
    const headerElement = document.querySelector('.pac-header-nav');
    if (displaySearch) {
      headerElement.style.marginBottom = 0;
      setDisplaySearch(false);
    } else {
      headerElement.style.marginBottom = 0;
      setDisplaySearch(true);
    }
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);
  // C L O S E   O N   E S C
  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      setDisplaySearch(false);
    }
  };

  return (
    <>
      <section className={`${styles.searchContainer} ${displaySearch ? styles.activeContainer : ''}`}>
        <SearchBar className="blog-nav-searchbar" active={displaySearch} closeSearch={handleShowSearch} />
      </section>
      <Layout>
        <CategoryNav
          displaySearch={displaySearch}
          setDisplaySearch={setDisplaySearch}
          handleShowSearch={handleShowSearch}
        />
        <Section className={styles.searchPage} containerClassName={styles.container} largeContainer>
          {!blogIndexLoading && <SearchResults posts={searchResults} query={formattedQ} />}
        </Section>
      </Layout>
    </>
  );
};

export default BlogSearchIndex(Search);
